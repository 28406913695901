import { useCallback, useEffect } from 'react';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getSuccessLoginEvent } from 'modules/gtmEvents/helpers/events/login/loginEventsHelper';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';
import { deleteCookie, getCookie } from 'cookies-next';
import { GA4_COOKIE_EVENT_DISPATCH } from 'components/loginModal/constants/Ga4LoginCookie';
import { decodeJson, encodeJson } from 'modules/url/encodeJson';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { LoginPositionRecords } from 'modules/gtmEvents/records/LoginPositionRecords';

export const useSuccessLoginEventListener = (): void => {
    const { gtm: { sendEvent }} = useAnalytics();
    const {
        set: setLocalStorageItem,
        get: getLocalStorageItem,
        remove: removeLocalStorageItem,
    } = useLocalStorageItem(GA4_COOKIE_EVENT_DISPATCH.name);

    const handleUcAuthLoginListener = useCallback(function (event: CustomEvent) {
        const detail = event.detail;
        if (detail) {
            const { loginMethod, loginPosition } = detail;

            if (loginMethod && loginPosition) {
                setLocalStorageItem(encodeJson({
                    method: loginMethod,
                    position: loginPosition,
                }));
            }
        } else {
            console.warn('Missing ucAuthLoginDialogActivated detail event data');
        }
        setTimeout(() => {
            location.reload();
        }, 500);
    }, [setLocalStorageItem]);

    useEffect(() => {
        window.document.addEventListener('ucAuthLoginDialogActivated', handleUcAuthLoginListener);
        return () => {
            window.removeEventListener('ucAuthLoginDialogActivated', handleUcAuthLoginListener);
        };
    }, [handleUcAuthLoginListener]);

    useEffect(() => {
        const ga4Cookie = getCookie(GA4_COOKIE_EVENT_DISPATCH.name, { path: GA4_COOKIE_EVENT_DISPATCH.path });
        if (ga4Cookie) {
            const decodedCookie: { method: string, position: string } = decodeJson(ga4Cookie.toString());
            if (decodedCookie.method === ELoginMethod.GOOGLE && decodedCookie.position === ELoginPosition.ONE_TAP) {
                sendEvent(
                    getSuccessLoginEvent(decodedCookie.method, ELoginPosition.ONE_TAP)
                );
                deleteCookie(GA4_COOKIE_EVENT_DISPATCH.name, { path: GA4_COOKIE_EVENT_DISPATCH.path });
            }
        }

        const localStorageEventData = getLocalStorageItem();
        if (localStorageEventData) {
            const decodedLocalStorageEventData: { method: string, position: string } = decodeJson(localStorageEventData.toString());
            sendEvent(
                getSuccessLoginEvent(
                    decodedLocalStorageEventData.method as ELoginMethod,
                    LoginPositionRecords[decodedLocalStorageEventData.position]
                )
            );
            removeLocalStorageItem();
        }

    }, [getLocalStorageItem, removeLocalStorageItem, sendEvent]);
};
