import { FC, useEffect } from 'react';
import GtmModule from 'react-gtm-module';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

const GoogleTagManager: FC = () => {
    const { gtm: { set: setGtm }} = useAnalytics();

    useEffect(() => {
        GtmModule.initialize({
            gtmId: AppConfigService.getGoogleTagManagerId(),
        });

        setGtm({
            sendEvent: event => {
                GtmModule.dataLayer({
                    dataLayer: event,
                });
            },
        });
    }, [setGtm]);

    return null;
};

export default GoogleTagManager;