import React, { FC } from 'react';
import { usePathname } from 'next/navigation';
import { INDEX } from 'modules/route/routes';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useSession from 'modules/stores/session/useSession';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { User } from 'components/layout/header/components/navbar/common/links/user/User';
import { AdminLink } from 'components/layout/header/components/navbar/common/links/admin/AdminLink';
import { ThemeModeSwitcherBtn } from 'modules/theme/components/themeModeSwitcher/ThemeModeSwitcherBtn';
import { AddAdvertiser } from 'components/layout/header/components/navbar/common/links/addAdvertisement/AddAdvertiser';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';

// TODO - one tap login - docasne vypnuty pre page okrem HPcky
export const Navigation: FC = () => {
    const { session, isLoading: isSessionLoading } = useSession();

    const pathname = usePathname();
    const isHomePage = pathname === INDEX;
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isHomePageMobileVersion = isHomePage && isMobile;

    const isLoggedIn = !!session;
    const nickname = session?.nick || '';
    const isAdmin = isLoggedIn && session.isAdmin;
    const showOneTap = isHomePage && !isSessionLoading && !isLoggedIn && !isHomePageMobileVersion;

    // useEffect(() => {
    //     setRedirectUrl(`/${pathname}`);
    // }, [pathname, setRedirectUrl]);

    return <Box display='flex' alignItems='center' justifyContent='end' gap={2}>

        {showOneTap && <Box
            component='div'
            id='g_id_onload'
            data-client_id={AppConfigService.getGoogleOauthClientId()}
            data-login_uri={`${AppConfigService.getOauthApiUrl()}/auth/google-${AppConfigService.getPlatformProjectName()}/callback`}
            data-auth_state={encodeURIComponent(JSON.stringify({
                callbackUrl: `${AppConfigService.getOauthCallbackUrl()}?method=${ELoginMethod.GOOGLE}&position=onetap`,
                appId: AppConfigService.getPlatformProjectName(),
            }))}
            data-cancel_on_tap_outside='false'
        ></Box>}

        {AppConfigService.getEnvironment() === 'development' && <ThemeModeSwitcherBtn/>}

        <AddAdvertiser isLoggedIn={isLoggedIn}/>

        <User isLoggedIn={isLoggedIn} nickName={nickname}/>

        {isAdmin && <AdminLink/>}

    </Box>;
};
