import { FC } from 'react';
import Link from 'components/link/Link';
import { Grid } from '@mui/material';
import Image from 'components/image/Image';
import { StaticImageData } from 'next/image';
import { Text } from 'modules/theme/components/text/Text';
import { Desktop } from 'modules/theme/components/responsive';

interface IItem {
    href: string;
    name: string;
    onClick?: () => void;
    icon: StaticImageData;
}

export const Item: FC<IItem> = ({ href, onClick, icon, name }) => {

    const onLinkClick = (event) => {
        if (onClick) {
            event.preventDefault();
            onClick();
        }
    };

    return <Link href={href} onClick={onLinkClick}>
        <Grid container spacing={1} alignItems='center'>

            <Grid item xs={12} lg='auto'>
                <Image src={icon} alt='logo' width={24} height={24}/>
            </Grid>

            <Grid item xs={false} lg='auto'>
                <Desktop>
                    <Text variant='label2' inverseColor>{name}</Text>
                </Desktop>
            </Grid>

        </Grid>
    </Link>;
};
