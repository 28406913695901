'use client';

import { FC } from 'react';
import useGdprCookieBar from 'modules/stores/cookieBar/useCookieBar';
import Gemius from 'components/analytics/Gemius';
import GoogleTagManager from 'components/analytics/GoogleTagManager';
import Livemonitor from 'components/analytics/Livemonitor';
import Hotjar from 'components/analytics/Hotjar';
import dynamic from 'next/dynamic';

const FacebookPixel = dynamic(() => import('./FacebookPixel'), { ssr: false });
const RtbHouse = dynamic(() => import('./RtbHouse'), { ssr: false });

const Main: FC = () => {
    const { isAllowed: { googleTagManager, facebookPixel, rtbHouse, livemonitor }} = useGdprCookieBar();

    return <>
        {googleTagManager && <GoogleTagManager />}
        {facebookPixel && <FacebookPixel />}
        <Gemius /> {/* This is OK */}
        {rtbHouse && <RtbHouse />}
        {livemonitor && <Livemonitor />}
        <Hotjar />
    </>;
};


export default Main;
