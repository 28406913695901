import { useCallback } from 'react';
import { useLayoutStore } from 'modules/stores/layout/useLayoutStore';

interface IReturn {
    percentage: number;
    setPercentage: (number: number) => void;
}

const useHeaderLoading = (): IReturn => {
    const headerLoaderState = useLayoutStore((store) => store.headerLoaderState);
    const setHeaderLoaderState = useLayoutStore((store) => store.setHeaderLoaderState);

    const setLoadingPercentage =
        useCallback(
            (percentage: number) => {
                if (percentage >= 0 && percentage <= 100) {
                    setHeaderLoaderState(percentage);
                }
            },
            [setHeaderLoaderState]
        );

    return {
        percentage: headerLoaderState,
        setPercentage: setLoadingPercentage,
    };
};

export default useHeaderLoading;
