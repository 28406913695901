import { FC } from 'react';
import Link from 'components/link/Link';
import { INDEX } from 'modules/route/routes';
import { IconButton, Box, Theme } from '@mui/material';
import LogoMobile from 'components/layout/header/svg/logoMobile.svg';
import { useMobileMenu } from 'components/layout/header/hooks/useMobileMenu';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Navigation: FC = () => {
    const {
        isOpen: isMobileMenuOpen,
        toggle: toggleMobileMenu,
        component: mobileMenuComponent,
    } = useMobileMenu();

    return <Box display='flex'>
        {mobileMenuComponent}

        <IconButton
            onClick={toggleMobileMenu}
            aria-label='Nehnutelnosti brand logotyp'
            sx={{
                marginRight: 1,
                backgroundColor: 'inherit',
                '&:hover': { backgroundColor: 'inherit' },
                color: (theme: Theme) => theme.palette.labelPrimary.main,
            }}
        >
            {isMobileMenuOpen ? <CloseIcon/> : <DragHandleIcon/>}
        </IconButton>

        <Link href={INDEX} sx={{ display: 'flex' }} ariaLabel='Nehnutelnosti brand logotyp'>
            <Svg component={LogoMobile} width={28} height={32}/>
        </Link>
    </Box>;
};
