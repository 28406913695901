import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Stack, Avatar, useTheme, Palette } from '@mui/material';
import { Wrapper } from 'components/layout/header/components/navbar/common/links/user/loggedIn/wrapper/Wrapper';

interface IMyAccount {
    nickName: string;
    href: string;
}

export const MyAccount: FC<IMyAccount> = ({ nickName, href }) => {
    const { palette } = useTheme();
    const initials = nickName.toUpperCase().slice(0, 2);

    return (
        <Wrapper href={href}>
            <Stack
                gap={0.75}
                paddingY={0.75}
                direction='row'
                paddingLeft={1}
                paddingRight={2}
                borderRadius={10}
                alignItems='center'
                justifyContent='center'
                bgcolor={palette.backgroundSecondary.main}
                sx={{
                    '&:hover': {
                        bgcolor: palette.backgroundTertiary.main,
                    },
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                        outline: `2px solid ${palette.labelPrimary.main}`,
                    },
                }}
            >
                <Avatar
                    sx={{
                        width: 26,
                        height: 26,
                        bgcolor: '#707070'
                    }}
                >
                    <Text
                        semibold
                        variant='label2'
                        color={(palette: Palette) =>
                            palette.labelPrimaryInverse
                        }
                    >
                        {initials}
                    </Text>
                </Avatar>

                <Text semibold variant='label1'>
                    {nickName}
                </Text>
            </Stack>
        </Wrapper>
    );
};
