'use client';

import { FC } from 'react';
import useLoading from 'modules/stores/loading/useLoading';
import { Box } from '@mui/material';

const PageLoaderListener: FC = () => {
    const { isLoading } = useLoading();

    if (!isLoading) {
        return null;
    }

    return <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            cursor: 'wait',

        }}
    ></Box>;

};

export default PageLoaderListener;