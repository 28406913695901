import { FC } from 'react';
import Script from 'next/script';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

const Livemonitor: FC = () => {
    const { livemonitor: { set }} = useAnalytics();

    return <Script
        id='script-analytics-livemonitor'
        strategy='afterInteractive'
        async={true}
        type='text/javascript'
        src={`//${AppConfigService.getAssetsServer()}/livemonitor/js/aztracker.js?v=1`}
        onLoad={() => {
            if (!window['__azTracker']) {
                window['__azTracker'] = [];
            }

            window['__azTracker'].push({ p:['setProjectName','nehnutelnosti']});
            window['__azTracker'].push({ p:['setNamingStrategy','keep']});

            set({
                sendEvent: (event) => {
                    const eventObj = {
                        p: [...event.p],
                    };
                    if(event.o){
                        eventObj['o'] = { ...event.o };
                    }
                    window['__azTracker'].push(eventObj);
                },
            });
        }}
    />;
};


export default Livemonitor;
