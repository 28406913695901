import { FC, useState, Children, MouseEventHandler, ReactNode } from 'react';
import Link from 'components/link/Link';
import { Badge, Menu, MenuItem as Item, Theme, Box, SxProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SvgIconComponent } from '@mui/icons-material';
import useOpenMenuState from 'modules/stores/layout/useOpenMenuState';
import { Text } from 'modules/theme/components/text/Text';

interface IProps {
    href?: string;
    text: string;
    isStrong?: boolean;
    Icon?: SvgIconComponent;
    image?: ReactNode; //@TODO fix this with janci
    badgeCount?: number;
    children?: ReactNode;
    sx?: SxProps<Theme>;
    openInNewTab?: boolean;
    onClick?: () => void;
}

const MenuItem: FC<IProps> = ({ href, text, isStrong, Icon, badgeCount, children, openInNewTab, onClick, sx, image }) => {
    const [anchorElement, setAnchorElement] = useState<HTMLAnchorElement|null>(null);
    const {
        open: openMenu,
        close: closeMenu,
        isOpen: isMenuOpen
    } = useOpenMenuState();
    const menuKey = `header-menu-item-${text}`;

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (!href) {
            event.preventDefault();
        }

        setAnchorElement(event.currentTarget);

        if (isMenuOpen(menuKey)) {
            closeMenu(menuKey);
        } else {
            openMenu(menuKey);
        }

        if (onClick) {
            onClick();
        }
    };

    return <>
        <Link
            href={href || '#'}
            onClick={handleOnClick}
            openInNewTab={openInNewTab}
            sx={{ position: 'relative', color: (theme: Theme) => theme.palette.labelPrimary.main, ...sx }}
        >

            <Text semibold={isStrong} component='span' variant='label2'>
                {text}
            </Text>

            <Box component='span' sx={{ marginLeft: 0.25 }}>
                <Badge badgeContent={badgeCount} sx={{
                    '& .MuiBadge-badge': {
                        padding: 0,
                        fontSize: '8px',
                        textAlign: 'center',
                        width: (theme: Theme) => theme.spacing(1.75),
                        height: (theme: Theme) => theme.spacing(1.75),
                        minWidth: (theme: Theme) => theme.spacing(1.75),
                        color: (theme: Theme) => theme.palette.labelPrimary.main,
                        border: (theme: Theme) => '1px solid ' + theme.palette.primary.main,
                        backgroundColor: (theme: Theme) => theme.palette.backgroundPrimary.main,
                    }
                }} showZero>
                    {Icon && (
                        <Icon sx={{
                            fontSize: (theme: Theme) => theme.typography.body1.fontSize
                        }} />
                    )}
                    {image && image}
                </Badge>
            </Box>
            {children && (
                <Text variant='body1' component='span'>
                    <ExpandMoreIcon sx={{
                        top: 4,
                        fontSize: 'inherit',
                        position: 'relative',
                        color: (theme: Theme) => theme.palette.labelPrimary.main,
                    }} />
                </Text>
            )}
        </Link>
        {children && (
            <Menu
                keepMounted={true}
                disablePortal={true}
                id={`menu-item-dropdown-${text}`}
                open={anchorElement !== null && isMenuOpen(menuKey)}
                onClose={() => {
                    closeMenu(menuKey);
                }}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: -10,
                    horizontal: 0
                }}
                sx={{
                    border: (theme: Theme) => `1px solid ${theme.palette.labelSecondary.main}`
                }}
            >
                {Children.map(children, child => (
                    <Item>
                        {child}
                    </Item>
                ))}
            </Menu>
        )}
    </>;
};

export default MenuItem;
