'use client';

import { FC, useEffect } from 'react';
import { getCookie, deleteCookie } from 'cookies-next';

const unusedCookies = ['prev_searched_locations'];

export const RemoveUnusedCookies: FC = () => {
    useEffect(() => {
        unusedCookies.forEach((cookie) => {
            if (getCookie(cookie)) {
                deleteCookie(cookie);
            }
        });
    }, []);

    return null;
};
