import React, { FC } from 'react';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { MyAccount as MobileMyAccount } from 'components/layout/header/components/navbar/common/links/user/loggedIn/mobile/MyAccount';
import { MyAccount as DektopMyAccount } from 'components/layout/header/components/navbar/common/links/user/loggedIn/desktop/MyAccount';

interface IMyAccount {
    nickName: string;
}

export const MyAccount: FC<IMyAccount> = ({ nickName }) => {
    const href = `${AppConfigService.getNehnutelnostiUrl()}/moje-konto`;

    return (
        <>
            <Desktop>
                <DektopMyAccount href={href} nickName={nickName} />
            </Desktop>

            <Mobile>
                <MobileMyAccount href={href} nickName={nickName.slice(0, 2)} />
            </Mobile>
        </>
    );
};
