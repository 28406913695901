import redirect from 'modules/route/redirect';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useSessionStore } from 'modules/stores/session/useSessionStore';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import { SearchAgent } from 'modules/api/clients/searchAgent/SearchAgent';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { LoginCta } from 'components/searchAgent/constants/authentication/login/LoginCta';

interface IReturn {
    setReporterId: (reporterId: number) => void;
    loginModal: ReactNode;
}

export const useSearchAgentAuthentication = (): IReturn => {
    const { session, isLoading, isInitialized } = useSessionStore();
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const { showLoginDialog, setLoginCtaText, loginModal } = useLoginModal();
    const [isProcessingCookie, setIsProcessingCookie] =
        useState<boolean>(false);

    const [authenticationNeeded, setAuthenticationNeeded] =
        useState<boolean>(false);
    const [reporterId, setReporterId] = useState<number>(0);

    const searchAgentIsAuthenticated = useCallback(async () => {
        let isAuthenticated: boolean;
        try {
            isAuthenticated =
                await new SearchAgent().searchAgentIsAuthenticated(reporterId);
        } catch (error) {
            console.error(error);
        }

        setAuthenticationNeeded(!isAuthenticated);
    }, [reporterId]);

    const isRegistered = useMemo(() => {
        return (
            !!session?.id &&
            !(
                session?.registrationCompletionUrl ||
                session?.acceptGdprConditionsUrl
            )
        );
    }, [session]);

    useEffect(() => {
        if (authenticationNeeded || !isRegistered) {
            return;
        }

        const searchAgentUrl = getCookie('search-agent-url') as string;
        if (searchAgentUrl) {
            deleteCookie('search-agent-url');
            if (window.location.href !== searchAgentUrl) {
                redirect(searchAgentUrl);
            }
        }
    }, [authenticationNeeded, isRegistered]);

    const authenticateSearchAgent = useCallback(async () => {
        try {
            await new SearchAgent().authenticateSearchAgent(reporterId);
        } catch (error) {
            console.error(error);
        } finally {
            searchAgentIsAuthenticated();
        }
    }, [reporterId, searchAgentIsAuthenticated]);

    useEffect(() => {
        if (reporterId) {
            searchAgentIsAuthenticated();
        }
    }, [searchAgentIsAuthenticated, reporterId]);

    useEffect(() => {
        if (isLoading || !isInitialized) {
            return;
        }
        setOpenLogin(!session?.id);
    }, [session, isLoading, isInitialized, setOpenLogin]);

    useEffect(() => {
        if (!authenticationNeeded) {
            return;
        }

        if (isRegistered) {
            authenticateSearchAgent();
        }

        if (isProcessingCookie) {
            return;
        }

        if (openLogin) {
            setOpenLogin(false);
            setLoginCtaText(LoginCta);
            showLoginDialog();
            setCookie('search-agent-url', window.location.href);
        }
    }, [
        openLogin,
        setOpenLogin,
        isRegistered,
        setLoginCtaText,
        showLoginDialog,
        isProcessingCookie,
        authenticationNeeded,
        authenticateSearchAgent,
    ]);

    useEffect(() => {
        if (!isRegistered) {
            return;
        }

        const searchAgentUrl = getCookie('search-agent-url');

        if (!searchAgentUrl) {
            return;
        }

        const url = new URL(searchAgentUrl as string);
        const reporterId =
            url?.searchParams?.get('reporter') &&
            parseInt(url.searchParams.get('reporter'));

        if (reporterId) {
            setIsProcessingCookie(true);
            setReporterId(reporterId);
        }
    }, [isRegistered, setReporterId, setIsProcessingCookie]);

    return {
        setReporterId,
        loginModal,
    };
};
