'use client';

import { FC, ReactNode } from 'react';
import ETheme from 'modules/theme/state/ETheme';
import useTheme from 'modules/theme/state/useTheme';
import defaultDarkTheme from 'modules/theme/theme/defaultDark';
import defaultLightTheme from 'modules/theme/theme/defaultLight';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

interface IThemeProvider {
    children: ReactNode;
}

export const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
    const { theme } = useTheme();
    return (
        <MuiThemeProvider
            theme={theme === ETheme.LIGHT ? defaultLightTheme : defaultDarkTheme}
        >
            {children}
        </MuiThemeProvider>
    );
};

