import {
    AppBar,
    Toolbar,
    Container,
    useMediaQuery,
    useTheme,
    Box,
} from '@mui/material';
import { FC } from 'react';
import { usePathname } from 'next/navigation';
import isHeaderHidden from 'modules/layout/isHeaderHidden';
import useLoading from 'modules/stores/loading/useLoading';
import useHeaderLoading from 'modules/stores/layout/useHeaderLoader';
import { Loading } from 'components/layout/header/components/loading/Loading';
import { NotificationCenter } from 'components/notificationCenter/NotificationCenter';

interface IHeaderWrapper {
    children: JSX.Element;
}

export const HeaderWrapper: FC<IHeaderWrapper> = ({ children }) => {
    const { isLoading } = useLoading();
    const { percentage } = useHeaderLoading();
    const { breakpoints, palette } = useTheme();
    const isMdDown = useMediaQuery(breakpoints.down('md'));
    const pathname = usePathname();

    const headerLoaderStyle = () => {
        const windowWidth = percentage === 0 ? 101 : 100;
        return {
            boxShadow: `${-windowWidth + percentage}vw 0px 0px 4px ${
                palette.backgroundPrimary.main
            }`,
        };
    };

    return (
        <Box
            top={0}
            left={0}
            zIndex={theme => theme.zIndex.appBar}
            width='100%'
            position={{ xs: 'fixed', md: 'relative' }}
        >
            {
                !(isHeaderHidden(pathname) && isMdDown) && <NotificationCenter />
            }
            <AppBar
                variant='header'
                enableColorOnDark
                sx={{
                    position: 'relative',
                    boxSizing: 'content-box',
                    boxShadow: headerLoaderStyle,
                    transition: 'box-shadow 0.3s ease-in-out',
                }}
            >
                <Toolbar
                    variant={isMdDown ? 'dense' : 'regular'}
                    disableGutters={true}
                    sx={{ color: '#222' }}
                >
                    <Container>{children}</Container>
                </Toolbar>

                {isLoading && <Loading />}
            </AppBar>
        </Box>
    );
};
