import { sessionApi } from 'modules/api/client';
import { deleteCookie, getCookie } from 'cookies-next';
import { useCallback, useEffect, useState } from 'react';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import { getCookieToken } from 'modules/session/helpers/getCookieToken';
import { useSessionStore } from 'modules/stores/session/useSessionStore';
import { SessionDtoToISessionMapper } from 'modules/session/helpers/SessionDtoToISessionMapper';

const useFetch = (): void => {
    const setSession = useSessionStore((store) => store.setSession);
    const setIsLoading = useSessionStore((store) => store.setIsLoading);
    const setIsInitialized = useSessionStore((store) => store.setIsInitialized);
    const setIsUserLoggedIn = useLoginStore((store) => store.setIsUserLoggedIn);
    const [refreshSession, setRefreshSession] = useState(false);

    const getSession = useCallback(async () => {
        if (!getCookieToken()) {
            setSession(null);
            setIsLoading(false);
            setIsInitialized(true);
            setIsUserLoggedIn(false);
            return;
        }

        setIsLoading(true);
        setIsInitialized(false);
        try {
            const sessionResponse = await sessionApi.getSession();

            if (sessionResponse.status !== 200) {
                throw `Response status was: ${sessionResponse.status}`;
            }

            if (!sessionResponse.data) {
                throw 'Response data empty';
            }

            const session = SessionDtoToISessionMapper(sessionResponse.data);

            setSession(session || undefined);
            setIsUserLoggedIn(!!session);

            if (session?.id && getCookie('refresh_session')) {
                deleteCookie('refresh_session');
                setRefreshSession(false);
            }
        } catch (error) {
            // TODO let system know
            setSession(null);

            if (getCookie('refresh_session')) {
                setRefreshSession(true);
            }
        } finally {
            setIsLoading(false);
            setIsInitialized(true);
        }
    }, [setSession, setIsLoading, setIsUserLoggedIn, setRefreshSession, setIsInitialized]);

    useEffect(() => {
        getSession();
    }, [getSession]);

    useEffect(() => {
        if (!refreshSession) {
            return;
        }
        const intervalId = setInterval(async () => {
            if (refreshSession) {
                await getSession();
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [refreshSession, getSession]);
};

export default useFetch;
