import { FC } from 'react';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import IData from 'components/layout/footer/interfaces/IData';
import { Button } from 'modules/theme/components/button/Button';
import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import validationSchema from 'components/layout/footer/constants/validationSchema';
import { Options } from 'components/layout/footer/components/suggestion/partials/reason/Options';
import { TextInput } from 'components/layout/footer/components/suggestion/partials/text/TextInput';
import { Title as TextTitle } from 'components/layout/footer/components/suggestion/partials/text/Title';
import { Title as OptionsTitle } from 'components/layout/footer/components/suggestion/partials/reason/Title';
import { Description } from 'components/layout/footer/components/suggestion/partials/description/Description';


interface ISuggestionDialogContent {
    isDisabled: boolean;
    onSubmit: (values: IData) => void;
}

export const SuggestionDialogContent: FC<ISuggestionDialogContent> = ({ isDisabled, onSubmit }) => {
    const formik = useFormik<IData>({
        initialValues: { reason: '', text: '', recaptchaToken: '' },
        validationSchema,
        onSubmit
    });

    const { touched, errors } = formik;
    const optionsErrorMessage = touched.reason && errors.reason ? errors.reason : undefined;
    const textInputErrorMessage = touched.text && errors.text ? errors.text : undefined;
    const repatchaErrorMessage = touched.recaptchaToken && errors.recaptchaToken ? errors.recaptchaToken : undefined;

    return <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <Description/>
            </Grid>

            <Grid item xs={12}>
                <OptionsTitle/>
            </Grid>

            <Grid item xs={12}>
                <Options
                    errorMsg={optionsErrorMessage}
                    value={formik.values.reason.toString()}
                    onChange={value => formik.setFieldValue('reason', value)}
                />
            </Grid>

            <Grid item xs={12}>
                <TextTitle/>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    isDisabled={isDisabled}
                    value={formik.values.text}
                    errMsg={textInputErrorMessage}
                    onChange={(value) => formik.setFieldValue('text', value)}
                />
            </Grid>

            <Grid item xs={12}>
                <Recaptcha
                    errorMsg={repatchaErrorMessage}
                    onChange={(token) => formik.setFieldValue('recaptchaToken', token)}
                />
            </Grid>

            <Grid item xs={12}>
                <Button fullWidth disabled={isDisabled} text='Poslať' type='submit'/>
            </Grid>
        </Grid>
    </form>;
};
