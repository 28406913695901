import { useCallback } from 'react';
import { useLayoutStore } from 'modules/stores/layout/useLayoutStore';

interface IReturn {
    open: (id: string) => void;
    close: (id: string) => void;
    isOpen: (id: string) => boolean;
}

const useOpenMenuState = (): IReturn => {
    const openMenuState = useLayoutStore((store) => store.openMenuState);
    const setOpenMenuState = useLayoutStore((store) => store.setOpenMenuState);

    const open = useCallback((id: string) => {
        const newOpenState = { ...openMenuState };
        for (const i in newOpenState) {
            newOpenState[i] = false;
        }
        newOpenState[id] = true;

        setOpenMenuState(newOpenState);
    }, [openMenuState, setOpenMenuState]);

    const close = useCallback((id: string) => {
        const newOpenState = { ...openMenuState };
        newOpenState[id] = false;

        setOpenMenuState(newOpenState);
    }, [openMenuState, setOpenMenuState]);

    const isOpen = useCallback((id: string): boolean => {
        return openMenuState[id] || false;
    }, [openMenuState]);

    return {
        open,
        close,
        isOpen
    };
};

export default useOpenMenuState;
