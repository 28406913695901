'use client';

import { FC, useEffect } from 'react';
import useCookieBar from 'modules/stores/cookieBar/useCookieBar';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { EConsentVendors } from 'components/gdpr/cookieBar/enums/EConsentVendors';
import { VendorGrantsType } from 'components/gdpr/cookieBar/types/VendorGrantsType';

const EVENT_NAMES = {
    GOOGLE_TAG_MANAGER: 'gdprCookieBarAllowGoogleTagManager',
    FACEBOOK_PIXEL: 'gdprCookieBarAllowFacebookPixel',
    GEMIUS: 'gdprCookieBarAllowGemius',
    RTBHOUSE: 'gdprCookieBarAllowRtbHouse',
    LIVEMONITOR: 'gdprCookieBarAllowLivemonitor',
    GOOGLE_DOUBLE_CLICK: 'gdprCookieBarAllowGoogleDoubleClick',
};

const dispatchGrants = (grants: VendorGrantsType) => {
    if (grants[EConsentVendors.GOOGLE_TAG_MANAGER].vendorGrant === true) {
        document.dispatchEvent(new Event(`${EVENT_NAMES.GOOGLE_TAG_MANAGER}`));
    }

    if (grants[EConsentVendors.FACEBOOK_PIXEL].vendorGrant === true) {
        document.dispatchEvent(new Event(`${EVENT_NAMES.FACEBOOK_PIXEL}`));
    }

    if (grants[EConsentVendors.GEMIUS].vendorGrant === true) {
        document.dispatchEvent(new Event(`${EVENT_NAMES.GEMIUS}`));
    }

    if (grants[EConsentVendors.RTBHOUSE].vendorGrant === true) {
        document.dispatchEvent(new Event(`${EVENT_NAMES.RTBHOUSE}`));

        // TODO when enable this?
        document.dispatchEvent(new Event(`${EVENT_NAMES.LIVEMONITOR}`));
    }

    if (grants[EConsentVendors.GOOGLE_DOUBLE_CLICK].vendorGrant === true) {
        document.dispatchEvent(new Event(`${EVENT_NAMES.GOOGLE_DOUBLE_CLICK}`));
    }
};

const Main: FC = () => {
    const { allow } = useCookieBar();

    useEffect(() => {
        const handleOnAllowGoogleTagManager = () => {
            allow('googleTagManager');
        };

        const handleOnAllowFacebookPixel = () => {
            allow('facebookPixel');
        };

        const handleOnAllowGemius = () => {
            allow('gemius');
        };

        const handleOnAllowRtbHouse = () => {
            allow('rtbHouse');
        };

        const handleOnAllowLivemonitor = () => {
            allow('livemonitor');
        };

        const handleOnAllowGoogleDoubleClick = () => {
            allow('googleDoubleClick');
        };

        document.addEventListener(
            EVENT_NAMES.GOOGLE_TAG_MANAGER,
            handleOnAllowGoogleTagManager
        );
        document.addEventListener(
            EVENT_NAMES.FACEBOOK_PIXEL,
            handleOnAllowFacebookPixel
        );
        document.addEventListener(EVENT_NAMES.GEMIUS, handleOnAllowGemius);
        document.addEventListener(EVENT_NAMES.RTBHOUSE, handleOnAllowRtbHouse);
        document.addEventListener(
            EVENT_NAMES.LIVEMONITOR,
            handleOnAllowLivemonitor
        );
        document.addEventListener(
            EVENT_NAMES.GOOGLE_DOUBLE_CLICK,
            handleOnAllowGoogleDoubleClick
        );

        return () => {
            document.removeEventListener(
                EVENT_NAMES.GOOGLE_TAG_MANAGER,
                handleOnAllowGoogleTagManager
            );
            document.removeEventListener(
                EVENT_NAMES.FACEBOOK_PIXEL,
                handleOnAllowFacebookPixel
            );
            document.removeEventListener(
                EVENT_NAMES.GEMIUS,
                handleOnAllowGemius
            );
            document.removeEventListener(
                EVENT_NAMES.RTBHOUSE,
                handleOnAllowRtbHouse
            );
            document.removeEventListener(
                EVENT_NAMES.LIVEMONITOR,
                handleOnAllowLivemonitor
            );
            document.removeEventListener(
                EVENT_NAMES.GOOGLE_DOUBLE_CLICK,
                handleOnAllowGoogleDoubleClick
            );
        };
    }, [allow]);

    useEffect(() => {
        // @ts-expect-error undefined property __tcfapi
        if (typeof __tcfapi === 'undefined') {
            return;
        }

        // @ts-expect-error undefined property __tcfapi
        __tcfapi('addEventListener', 2, function (tcData, success) {
            if (tcData.gdprApplies === true) {
                if (
                    success &&
                    (tcData.eventStatus === 'tcloaded' ||
                        tcData.eventStatus === 'useractioncomplete')
                ) {
                    // @ts-expect-error undefined property __tcfapi
                    __tcfapi(
                        'getCustomVendorConsents',
                        2,
                        function (vendorConsents, success) {
                            if (success) {
                                dispatchGrants(vendorConsents.grants);
                            }
                        }
                    );
                }
            } else {
                document.dispatchEvent(
                    new Event(`${EVENT_NAMES.GOOGLE_TAG_MANAGER}`)
                );
                document.dispatchEvent(
                    new Event(`${EVENT_NAMES.FACEBOOK_PIXEL}`)
                );
                document.dispatchEvent(new Event(`${EVENT_NAMES.GEMIUS}`));
                document.dispatchEvent(new Event(`${EVENT_NAMES.RTBHOUSE}`));
                document.dispatchEvent(new Event(`${EVENT_NAMES.LIVEMONITOR}`));
                document.dispatchEvent(
                    new Event(`${EVENT_NAMES.GOOGLE_DOUBLE_CLICK}`)
                );
            }
        });
    }, []);

    const isProduction = AppConfigService.getEnvironment() === 'production';

    useEffect(() => {
        if (!isProduction) {
            allow('googleTagManager');
            allow('facebookPixel');
            allow('gemius');
            allow('rtbHouse');
            allow('livemonitor');
            allow('googleDoubleClick');
        }
    }, [allow, isProduction]);

    return null;
};

export default Main;
