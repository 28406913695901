import { ThemeOptions } from '@mui/material';
import { Work_Sans } from 'next/font/google';

const work_sans = Work_Sans({
    weight: ['400', '600', '700'],
    subsets: ['latin'],
    style: ['normal', 'italic'],
    display: 'swap',
    variable: '--font-work-sans',
});

const config: ThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'input, button, textarea, select': {
                    font: 'inherit'
                }
            }
        }
    },
    typography: {
        fontFamily: work_sans.style.fontFamily,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        display1: {
            fontSize: '120px',
            lineHeight: '140px',
        },
        display2: {
            fontSize: '96px',
            lineHeight: '112px',
        },
        display3: {
            fontSize: '64px',
            lineHeight: '72px',
        },
        display4: {
            fontSize: '56px',
            lineHeight: '64px',
        },
        display5: {
            fontSize: '48px',
            lineHeight: '56px',
        },
        h1: {
            fontSize: '40px',
            lineHeight: '46px',
        },
        h2: {
            fontSize: '36px',
            lineHeight: '42px',
        },
        h3: {
            fontSize: '32px',
            lineHeight: '36px',
        },
        h4: {
            fontSize: '24px',
            lineHeight: '28px',
        },
        h5: {
            fontSize: '20px',
            lineHeight: '24px',
        },
        h6: {
            fontSize: '18px',
            lineHeight: '24px',
        },
        body1: {
            fontSize: '18px',
            lineHeight: '28px',
        },
        body2: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        body3: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        label1: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        label2: {
            fontSize: '12px',
            lineHeight: '16px',
        },
        footnote: {
            fontSize: '11px',
            lineHeight: '16px',
        },
        breadcrumb: {
            fontSize: '10px',
            lineHeight: '16px',
        },
    },
    // breakpoints: {
    //     values: {
    //         xs: 0,
    //         sm: 550,
    //         md: 744,
    //         lg: 950,
    //         xl: 1128,
    //         xxl: 1440,
    //     },
    // },
};

export default config;
