import React, { FC, ReactNode } from 'react';
import Link from 'components/link/Link';

export interface IWrapper {
    href: string;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({ href, children }) => {
    return <Link href={href} noUnderline> {children} </Link>;
};
