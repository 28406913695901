'use client';

import { FC, useEffect, useState } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { UpdateBrowserModalContent } from 'components/updateBrowserModal/partials/content/UpdateBrowserModalContent';
import { isBrowserSupported } from 'components/updateBrowserModal/utils/checkBrowserSupport';

export const UpdateBrowserModal: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isSupported = isBrowserSupported(userAgent);
        if (!isSupported) {
            console.log('Unsupported browser');
            setIsModalOpen(true);
        }
    }, []);

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const isDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('md')
    );

    return (
        <>
            {isModalOpen && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 1100,
                        boxSizing: 'border-box',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: isDesktop ? '32px' : '16px',
                            maxWidth: isDesktop ? '400px' : '350px',
                            borderRadius: '16px',
                            textAlign: 'center',
                        }}
                    >
                        <UpdateBrowserModalContent onClose={handleClose} />
                    </div>
                </div>
            )}
        </>
    );
};
