'use client';

import { FC, useEffect } from 'react';
import { useSearchAgentAuthentication } from 'components/searchAgent/hooks/useSearchAgentAuthentication';
import { useSearchParams } from 'next/navigation';

export const AuthenticateSearchAgent: FC = () => {
    const searchParams = useSearchParams();


    const { setReporterId, loginModal } = useSearchAgentAuthentication();

    useEffect(() => {
        const reporterId = searchParams?.get('reporter');

        if (reporterId) {
            setReporterId(parseInt(reporterId));
        }

    }, [setReporterId, searchParams]);

    return (
        <>{loginModal}</>
    );
};
