'use client';

import { createTheme } from '@mui/material';
import mediaQuery from 'css-mediaquery';
import defaultThemeConfig from 'modules/theme/theme/defaultThemeConfig';
import checkIcon from 'modules/theme/components/fields/switch/svg/checkDarkMode.svg?url';
import selectedItemCheckedDark from 'modules/theme/components/fields/select/svg/selectedItemCheckedDark.svg?url';
import TintArrowRight from 'modules/theme/components/icons/basic/TintArrowRight.svg?url';
import TintArrowLeft from 'modules/theme/components/icons/basic/TintArrowLeft.svg?url';

const theme = createTheme(defaultThemeConfig);
const PALETTE_PRIMARY_MAIN = '#E40046';

const defaultDarkTheme = createTheme({
    ...theme,
    palette: {
        mode: 'dark',
        primary: {
            main: PALETTE_PRIMARY_MAIN,
            contrastText: '#FFFFFF',
        },
        divider: '#38383A',
        dividerInverse: '#C6C6C8',
        dividerTransparent: 'rgba(84, 84, 88, 0.64)',
        dividerTransparentInverse: 'rgba(182, 182, 182, 0.36)',
        tintRed: {
            main: '#AB2328',
        },
        tintMint: {
            main: '#007AFF',
        },
        tintTeal: {
            main: '#30B0C7',
        },
        tintCyan: {
            main: '#32ADE6',
        },
        tintBlue: {
            main: '#307FE2',
        },
        tintPink: {
            main: '#CE0F69',
        },
        tintError: {
            main: '#AB2328',
        },
        tintBlack: {
            main: '#000000',
        },
        tintGreen: {
            main: '#00843D',
        },
        tintBrown: {
            main: '#A2845E',
        },
        tintOlive: {
            main: '#10505B',
        },
        tintWhite: {
            main: '#FFFFFF',
        },
        tintOrange: {
            main: '#E57200',
        },
        tintYellow: {
            main: '#FFCD00',
        },
        tintIndigo: {
            main: '#5856D2',
        },
        tintPurple: {
            main: '#894B9D',
        },
        tintPurpleBg: {
            main: '#ECE4F0',
        },
        tintErrorBg: {
            main: '#FAF2F2',
        },
        error: {
            main: '#E40046',
        },
        labelPrimary: {
            main: '#F5F5F7',
        },
        labelPrimaryInverse: {
            main: '#2D2926',
        },
        labelSecondary: {
            main: 'rgba(235, 235, 245, 0.64)',
        },
        labelSecondaryInverse: {
            main: '#707372',
        },
        labelTertiary: {
            main: 'rgba(235, 235, 245, 0.32)',
        },
        labelTertiaryInverse: {
            main: '#B1B3B3',
        },
        labelQuarternary: {
            main: 'rgba(235, 235, 245, 0.16)',
        },
        labelQuarternaryInverse: {
            main: '#D6D9D9',
        },
        backgroundPrimary: {
            main: '#000000',
        },
        backgroundPrimaryElevated: {
            main: '#1C1C1E',
        },
        backgroundPrimaryInverse: {
            main: '#FFFFFF',
        },
        backgroundSecondary: {
            main: '#1C1C1E',
        },
        backgroundSecondaryElevated: {
            main: '#2C2C2E',
        },
        backgroundSecondaryInverse: {
            main: '#F7F7F7',
        },
        backgroundTertiary: {
            main: '#3A3A3C',
        },
        backgroundTertiaryElevated: {
            main: '#3A3A3C',
        },
        backgroundTertiaryInverse: {
            main: '#EEEEEE',
        },
        background: {
            default: '#000000',
        },
        grey: {
            100: '#F7F7F7',
            200: '#E8E8E8',
            400: '#B1B3B3',
            800: '#3D3E40',
            900: '#2D2926',
        },
        identityDarkRed: {
            main: '#651C32',
        },
        identityBasicRed: {
            main: '#E40046',
        },
        identityLightRed: {
            main: '#E5C8C3',
        },
        identityDarkPink: {
            main: '#840B55',
        },
        identityBasicPink: {
            main: '#CE0F69',
        },
        identityLightPink: {
            main: '#F7D9E7',
        },
        identityDarkGreen: {
            main: '#024638',
        },
        identityBasicGreen: {
            main: '#84BD00',
        },
        identityLightGreen: {
            main: '#EBF4D6',
        },
        identityDarkBlue: {
            main: '#001A72',
        },
        identityBasicBlue: {
            main: '#307FE2',
        },
        identityLightBlue: {
            main: '#DEEBFA',
        },
        identityDarkYellow: {
            main: '#9F7D23',
        },
        identityBasicYellow: {
            main: '#FFCD00',
        },
        identityLightYellow: {
            main: '#FFF7D6',
        },
        identityDarkGray: {
            main: '#2D2926',
        },
        identityBasicGray: {
            main: '#898D8D',
        },
        identityLightGray: {
            main: '#ECEDED',
        },
    },
});

const inputsMergedFirstChildStyles = {
    '& .MuiFormControl-root:first-child': {
        '& .MuiOutlinedInput-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            '& fieldset': {
                border: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderLeft: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderRight: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderTop: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderTop: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}!important`,
                    borderBottom: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}!important`,
                },
            },
            '&.Mui-error': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.tintRed,
                '& fieldset': {
                    borderBottom: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                },
                '&:hover': {
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                },
            },
            '&.Mui-disabled': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.labelTertiary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            display: 'none',
        },
    },
};

const inputsMergedLastChildStyles = {
    '& .MuiFormControl-root:last-child': {
        '& .MuiOutlinedInput-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            '& fieldset': {
                borderLeft: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderRight: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderBottom: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderLeft: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderRight: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderBottom: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            },
            '&.Mui-error': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.tintRed,
                '& fieldset': {
                    borderBottom: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                },
                '&:hover': {
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                },
            },
            '&.Mui-disabled': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.labelTertiary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            display: 'none',
        },
    },
};

const inputsMergedBasicChildStyles = {
    '& .MuiFormControl-root': {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderLeft: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderRight: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}`,
                borderTop: 'none',
                borderRadius: 0,
            },
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderTop: 'none!important',
                    borderBottom: `1px solid ${defaultDarkTheme.palette.labelTertiary.main}!important`,
                },
            },
            '&.Mui-error': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.tintRed,
                '&:hover': {
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    '& fieldset': {
                        borderBottom: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                        borderTop: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: 'none!important',
                        borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                    },
                },
                '&.Mui-focused': {
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    background: defaultDarkTheme.palette.backgroundPrimary.main,
                    '& fieldset': {
                        borderBottom: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                        borderTop: `1px solid ${defaultDarkTheme.palette.tintRed}`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderLeft: `1px solid ${defaultDarkTheme.palette.tintRed}!important`,
                    borderRight: `1px solid ${defaultDarkTheme.palette.tintRed}!important`,
                    borderTop: `1px solid ${defaultDarkTheme.palette.tintRed}!important`,
                },
            },
            '&.Mui-disabled': {
                backgroundColor:
                    defaultDarkTheme.palette.backgroundSecondary.main,
                color: defaultDarkTheme.palette.labelTertiary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            display: 'none',
        },
    },
};

export default createTheme(theme, {
    ...defaultDarkTheme,
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                ssrMatchMedia: (query: string) => {
                    const isMatch = mediaQuery.match(query, {
                        width: theme.breakpoints.values.md,
                    });

                    return {
                        matches: !isMatch,
                    };
                },
            },
        },

        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                dense: {
                    minHeight: '64px',
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: '48px',
                    borderRadius: '8px',
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    fontSize: defaultThemeConfig.typography['body2'].fontSize,
                    lineHeight:
                        defaultThemeConfig.typography['body2'].lineHeight,
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                    '& fieldset': {
                        borderColor: `${defaultDarkTheme.palette.labelTertiary.main}`,
                    },
                    '&.Mui-error': {
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundSecondary.main,
                        color: defaultDarkTheme.palette.tintRed,
                        borderColor: `${defaultDarkTheme.palette.tintRed}!important`,
                    },
                    '&.Mui-disabled': {
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundSecondary.main,
                        color: defaultDarkTheme.palette.labelTertiary.main,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                    },
                    '&.Mui-focused': {
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid!important',
                            borderColor: `${defaultDarkTheme.palette.labelTertiary.main}!important`,
                        },
                    },
                    '& .MuiInputAdornment-positionEnd': {
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        cursor: 'pointer',
                    },
                    '&.MuiInputBase-multiline': {
                        padding: '12px 14px',
                        height: 'auto',
                    },
                    '& legend': {
                        width: '0',
                    },
                },
            },
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&:has(+ .MuiInputBase-root) + .MuiInputBase-root .MuiSelect-select':
                        {
                            paddingBottom: '1px !important',
                        },
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: defaultThemeConfig.typography['label2'].fontSize,
                    color: defaultDarkTheme.palette.labelSecondary.main,
                    '&.Mui-focused': {
                        color: defaultDarkTheme.palette.labelSecondary.main,
                    },
                    '& + .MuiOutlinedInput-root': {
                        height: '64px',
                        '& input': {
                            paddingBottom: '4px',
                        },
                    },
                },
                outlined: {
                    top: '-5px',
                    transform: 'translate(14px, 16px) scale(1)',
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundSecondary.main,
                    '&.Mui-disabled': {
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundSecondary.main,
                    },
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: defaultDarkTheme.palette.labelPrimary.main,
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        paddingTop: '6px !important',
                        '& .MuiInputBase-input': {
                            paddingLeft: '3px',
                            paddingBottom: '6px !important',
                        },
                    },
                    '&:has(label) .MuiOutlinedInput-root': {
                        paddingBottom: '1px !important',
                        '& .MuiInputBase-input': {
                            paddingLeft: '6px',
                            paddingBottom: '1px !important',
                        },
                    },
                },
                listbox: {
                    overflowY: 'scroll',
                    'scrollbar-width': 'none' /* Firefox */,
                    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
                    '&::-webkit-scrollbar': {
                        display: 'none' /* Safari and Chrome */,
                    },
                },
                noOptions: {
                    fontSize: defaultDarkTheme.typography['body2'].fontSize,
                },
                endAdornment: {
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    backgroudColor:
                        defaultDarkTheme.palette.backgroundPrimary.main +
                        '!important',
                    '& button.MuiIconButton-root': {
                        color: 'inherit',
                        backgroundColor: 'inherit',
                    },
                },
            },
        },

        MuiPaper: {
            variants: [
                {
                    props: { variant: 'header' },
                    style: {
                        height: '64px',
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundPrimary.main +
                            '!important',
                    },
                },
                {
                    props: { variant: 'footer' },
                    style: {
                        backgroundColor:
                            defaultDarkTheme.palette.labelPrimary.main +
                            '!important',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:has(.UCRealEstateDesignSystem-SelectMenuItem)': {
                        '& .MuiMenu-list': {
                            backgroundColor: 'inherit',
                            '& *': {
                                whiteSpace: 'normal',
                            },
                        },
                        minWidth: '100%',
                        borderRadius: '12px',
                        boxShadow: `0px 8px 24px ${defaultDarkTheme.palette.labelTertiary.main}`,
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundPrimary.main +
                            '!important',
                    },
                },
            },
        },

        MuiCard: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&.UCRealEstateDesignSystem-InputsMerged': {
                        borderBottom: 'none',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        width: '100%',
                        '& .MuiAutocomplete-root': {
                            ...inputsMergedFirstChildStyles,
                        },
                        ...inputsMergedFirstChildStyles,
                        ...inputsMergedLastChildStyles,
                        ...inputsMergedBasicChildStyles,
                    },
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.UCRealEstateDesignSystem-SelectMenuItem': {
                        minHeight: '44px',
                        paddingLeft: '40px',
                        paddingRight: '10px',
                        fontSize:
                            defaultThemeConfig.typography['body2'].fontSize,
                        backgroundColor:
                            defaultDarkTheme.palette.backgroundPrimary.main,
                        '&:hover': {
                            backgroundColor:
                                defaultDarkTheme.palette.backgroundSecondary
                                    .main,
                        },
                        '&.Mui-selected': {
                            paddingLeft: '16px',
                            position: 'relative',
                            backgroundColor:
                                defaultDarkTheme.palette.backgroundPrimary.main,
                            '&:hover': {
                                backgroundColor:
                                    defaultDarkTheme.palette.backgroundPrimary
                                        .main,
                            },
                            '::before': {
                                display: 'block',
                                width: '24px',
                                content: '" "',
                                height: '24px',
                                backgroundPositionY: '2px',
                                backgroundPositionX: '-4px',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url('${selectedItemCheckedDark.src}')`,
                            },
                        },
                    },
                },
            },
        },
        MuiChip: {
            defaultProps: {
                size: 'normal',
            },
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderRadius: '99px',
                    borderColor: defaultDarkTheme.palette.labelQuarternary.main,
                    backgroundColor:
                        defaultDarkTheme.palette.backgroundPrimary.main,
                    '&.Mui-disabled': {
                        opacity: 1,
                        borderColor:
                            defaultDarkTheme.palette.labelTertiary.main,
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        backgroundColor:
                            defaultDarkTheme.palette.labelTertiary.main,
                    },
                },
                clickable: {
                    '&:hover, &:focus': {
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        backgroundColor:
                            defaultDarkTheme.palette.labelPrimary.main,
                    },
                    '&:focus': {
                        borderColor: defaultDarkTheme.palette.labelPrimary.main,
                    },
                },
                sizeNormal: {
                    height: '48px',
                    fontSize: defaultThemeConfig.typography['body2'].fontSize,
                },
                sizeMedium: {
                    height: '40px',
                    fontSize: defaultThemeConfig.typography['body3'].fontSize,
                },
                sizeSmall: {
                    height: '32px',
                    fontSize: defaultThemeConfig.typography['label2'].fontSize,
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                colorPrimary: {
                    padding: 0,
                },
                root: {
                    padding: 0,
                    width: '48px',
                    height: '32px',
                },
                switchBase: {
                    '&.Mui-checked': {
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        '& .MuiSwitch-thumb': {
                            left: '-2px',
                        },
                    },
                },
                thumb: {
                    color: defaultDarkTheme.palette.backgroundPrimary.main,
                    top: '2px',
                    left: '2px',
                    height: '28px',
                    width: '28px',
                    position: 'relative',
                    '.Mui-checked &': {
                        '::after': {
                            display: 'block',
                            width: '100%',
                            content: '" "',
                            height: '100%',
                            backgroundSize: '11px 8px',
                            backgroundPositionX: '10px',
                            backgroundPositionY: '10px',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url('${checkIcon.src}')`,
                        },
                    },
                },
                track: {
                    '.MuiSwitch-switchBase + &': {
                        opacity: 1,
                        background: defaultDarkTheme.palette.labelTertiary.main,
                    },
                    '.MuiSwitch-switchBase.Mui-checked + &': {
                        opacity: 1,
                        background: defaultDarkTheme.palette.labelPrimary.main,
                    },
                    padding: '2px',
                    borderRadius: '999px',
                },
            },
        },

        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-disabled)': {
                        '&:has(.MuiCheckbox-root):hover svg rect, &:has(.MuiRadio-root):hover svg circle':
                            {
                                stroke: defaultDarkTheme.palette.labelPrimary
                                    .main,
                            },
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    margin: '8px',
                    width: '18px',
                    height: '18px',
                    marginLeft: '12px',
                    borderRadius: '4px',
                    '&:hover svg rect': {
                        stroke: defaultDarkTheme.palette.labelPrimary.main,
                        fill: defaultDarkTheme.palette.backgroundPrimary.main,
                    },
                    '& svg rect': {
                        stroke: defaultDarkTheme.palette.labelTertiary.main,
                        fill: defaultDarkTheme.palette.backgroundPrimary.main,
                    },
                    '& + span': {
                        fontSize:
                            defaultThemeConfig.typography['body2'].fontSize,
                    },
                    '&.Mui-checked': {
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        '& svg path': {
                            stroke: defaultDarkTheme.palette.backgroundPrimary
                                .main,
                        },
                        '& svg rect': {
                            stroke: defaultDarkTheme.palette.backgroundPrimary
                                .main,
                            fill: defaultDarkTheme.palette.labelPrimary.main,
                        },
                    },
                    '&.Mui-disabled': {
                        '+ span': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                        },
                        '& svg rect': {
                            fill: defaultDarkTheme.palette.backgroundSecondary
                                .main,
                        },
                    },
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    margin: '8px',
                    width: '18px',
                    height: '18px',
                    marginLeft: '12px',
                    borderRadius: '4px',
                    '& svg circle': {
                        stroke: defaultDarkTheme.palette.labelTertiary.main,
                        fill: defaultDarkTheme.palette.backgroundPrimary.main,
                    },
                    '& + span': {
                        fontSize:
                            defaultThemeConfig.typography['body2'].fontSize,
                    },
                    '&.Mui-checked': {
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        '& svg circle': {
                            stroke: defaultDarkTheme.palette.labelPrimary.main,
                            '+ circle': {
                                fill: defaultDarkTheme.palette.labelPrimary
                                    .main,
                            },
                        },
                    },
                    '&.Mui-disabled': {
                        '+ span': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                        },
                        '& svg circle': {
                            fill: defaultDarkTheme.palette.backgroundSecondary
                                .main,
                        },
                    },
                },
            },
        },

        MuiButton: {
            defaultProps: {
                variant: 'primary',
                size: 'medium',
            },
            variants: [
                {
                    props: { variant: 'primary' },
                    style: {
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        background:
                            'linear-gradient(91.16deg, #E40046 -0.01%, #CE0F69 100.02%)',
                        '&:hover': {
                            background:
                                'linear-gradient(91.16deg, #F23057 -0.01%, #DB1672 100.02%)',
                        },
                        '&.Mui-disabled': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                            background:
                                defaultDarkTheme.palette.backgroundTertiary
                                    .main,
                        },
                    },
                },
                {
                    props: { variant: 'rounded' },
                    style: {
                        borderRadius: '32px',
                        padding: '32px 32px !important',
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        background:
                            'linear-gradient(91.16deg, #E40046 -0.01%, #CE0F69 100.02%)',
                        '&:hover': {
                            background:
                                'linear-gradient(91.16deg, #F23057 -0.01%, #DB1672 100.02%)',
                        },
                        '&.Mui-disabled': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                            background:
                                defaultDarkTheme.palette.backgroundTertiary
                                    .main,
                        },
                    },
                },
                {
                    props: { variant: 'secondary' },
                    style: {
                        color: defaultDarkTheme.palette.backgroundPrimary.main,
                        background: defaultDarkTheme.palette.labelPrimary.main,
                        '&:hover': {
                            background:
                                defaultDarkTheme.palette.labelSecondary.main,
                            boxShadow: 'none',
                        },
                        '&.Mui-disabled': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                            background:
                                defaultDarkTheme.palette.backgroundTertiary
                                    .main,
                        },
                        '@media (hover: none)': {
                            '&:hover': {
                                backgroundColor: `${defaultDarkTheme.palette.labelPrimary.main} !important`,
                            },
                        },
                    },
                },
                {
                    props: { variant: 'tertiary' },
                    style: {
                        border: '1px solid',
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        background:
                            defaultDarkTheme.palette.backgroundPrimary.main,
                        borderColor: defaultDarkTheme.palette.labelPrimary.main,
                        '&:hover': {
                            color: defaultDarkTheme.palette.backgroundPrimary
                                .main,
                            background:
                                defaultDarkTheme.palette.labelPrimary.main,
                        },
                        '&.Mui-disabled': {
                            border: '2px solid',
                            color: defaultDarkTheme.palette.labelQuarternary
                                .main,
                            borderColor:
                                defaultDarkTheme.palette.labelQuarternary.main,
                            background:
                                defaultDarkTheme.palette.backgroundSecondary
                                    .main,
                            '&.MuiButton-secondarySizeSmall': {
                                border: '1px solid !important',
                            },
                        },
                    },
                },
                {
                    props: { variant: 'quaternary' },
                    style: {
                        ':hover': {
                            textDecoration: 'underline',
                            background:
                                defaultDarkTheme.palette.backgroundTertiary
                                    .main,
                        },
                        textDecoration: 'underline',
                        fontSize:
                            defaultThemeConfig.typography['body2'].fontSize,
                        lineHeight:
                            defaultThemeConfig.typography['body2'].lineHeight,
                        background:
                            defaultDarkTheme.palette.backgroundSecondary.main,
                        fontWeight:
                            defaultThemeConfig.typography['fontWeightRegular'],
                        '&.Mui-disabled': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                        },
                    },
                },
                {
                    props: { variant: 'text' },
                    style: {
                        ':hover': {
                            textDecoration: 'underline',
                            background: 'inherit',
                        },
                        textDecoration: 'underline',
                        color: defaultDarkTheme.palette.labelPrimary.main,
                        fontSize:
                            defaultThemeConfig.typography['body2'].fontSize,
                        lineHeight:
                            defaultThemeConfig.typography['body2'].lineHeight,
                        fontWeight:
                            defaultThemeConfig.typography['fontWeightRegular'],
                        '&.Mui-disabled': {
                            color: defaultDarkTheme.palette.labelTertiary.main,
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    fontSize: defaultThemeConfig.typography['body2'].fontSize,
                    lineHeight:
                        defaultThemeConfig.typography['body2'].lineHeight,
                    fontWeight:
                        defaultThemeConfig.typography['fontWeightMedium'],
                },
                sizeMedium: {
                    height: '48px',
                    padding: '12px 16px',
                },
                sizeSmall: {
                    height: '40px',
                    padding: '10px 16px',
                    fontSize: defaultThemeConfig.typography['body3'].fontSize,
                },
            },
        },

        MuiPagination: {
            defaultProps: {
                size: 'small',
                variant: 'text',
                color: 'primary',
                shape: 'rounded',
            },
            variants: [
                {
                    props: {
                        variant: 'text',
                        size: 'small',
                        shape: 'rounded',
                        color: 'primary',
                    },
                    style: {
                        ul: {
                            li: {
                                button: {
                                    gap: '15px',
                                    width: '46px',
                                    height: '48px',
                                    borderRadius: '8px',
                                    color: defaultDarkTheme.palette.labelPrimary
                                        .main,
                                    fontSize:
                                        defaultThemeConfig.typography['body3']
                                            .fontSize,
                                    fontWeight:
                                        defaultThemeConfig.typography[
                                            'fontWeightBold'
                                        ],
                                    '&.Mui-selected': {
                                        color: defaultDarkTheme.palette
                                            .labelPrimary.main,
                                        background:
                                            defaultDarkTheme.palette
                                                .backgroundSecondary.main,
                                        border: `2px solid ${defaultDarkTheme.palette.labelPrimary.main}`,
                                        '&:hover': {
                                            backgroundColor:
                                                defaultDarkTheme.palette
                                                    .backgroundSecondary.main,
                                        },
                                    },
                                },
                                '&:last-child,  &:first-child': {
                                    button: {
                                        '&:has(svg)': {
                                            svg: {
                                                display: 'none',
                                            },
                                            '::before': {
                                                display: 'block',
                                                width: '8px',
                                                content: '" "',
                                                height: '15px',
                                                backgroundPositionY: '2px',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: `url('${TintArrowRight.src}')`,
                                            },
                                        },
                                    },
                                },
                                '&:first-child': {
                                    button: {
                                        '&:has(svg)': {
                                            '::before': {
                                                backgroundImage: `url('${TintArrowLeft.src}')`,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            ],
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    opacity: 1,
                    padding: '22px 0',
                    marginRight: '32px',
                    textTransform: 'none',
                    fontSize: theme.typography.body2.fontSize,
                    color: defaultDarkTheme.palette.labelSecondary.main,
                    '&[aria-selected="true"]': {
                        color: defaultDarkTheme.palette.labelPrimary.main,
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '28px 0',
                    },
                },
            },
        },

        MuiAlert: {
            variants: [
                {
                    props: { variant: 'standard', severity: 'success' },
                    style: {
                        background:
                            defaultDarkTheme.palette.backgroundPrimaryElevated
                                .main,

                        '&': {
                            '.wrapper': {
                                padding: 6,
                                borderRadius: 8,
                                display: 'flex',
                                background:
                                    defaultDarkTheme.palette.identityLightGreen
                                        .main,
                            },
                            svg: {
                                color: defaultDarkTheme.palette
                                    .identityBasicGreen.main,
                            },
                        },
                    },
                },
                {
                    props: { variant: 'standard', severity: 'info' },
                    style: {
                        background:
                            defaultDarkTheme.palette.backgroundPrimaryElevated
                                .main,
                        '&': {
                            '.wrapper': {
                                padding: 6,
                                borderRadius: 8,
                                display: 'flex',
                                background:
                                    defaultDarkTheme.palette.identityLightBlue
                                        .main,
                            },
                            svg: {
                                color: defaultDarkTheme.palette
                                    .identityBasicBlue.main,
                            },
                        },
                    },
                },
                {
                    props: { variant: 'standard', severity: 'error' },
                    style: {
                        background:
                            defaultDarkTheme.palette.backgroundPrimaryElevated
                                .main,
                        '&': {
                            '.wrapper': {
                                padding: 6,
                                borderRadius: 8,
                                display: 'flex',
                                background:
                                    defaultDarkTheme.palette.identityLightPink
                                        .main,
                            },
                            svg: {
                                color: defaultDarkTheme.palette.identityBasicRed
                                    .main,
                            },
                        },
                    },
                },
                {
                    props: { variant: 'standard', severity: 'basic' },
                    style: {
                        background:
                            defaultDarkTheme.palette.backgroundPrimaryElevated
                                .main,

                        '&': {
                            '.wrapper': {
                                padding: 6,
                                borderRadius: 8,
                                display: 'flex',
                                background:
                                    defaultDarkTheme.palette.identityLightPink
                                        .main,
                            },
                            svg: {
                                color: defaultDarkTheme.palette.identityBasicRed
                                    .main,
                            },
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    height: 56,
                    borderRadius: 16,
                    alignItems: 'center',
                    backdropFilter: 'blur(16px)',
                    padding: '12px 16px 12px 12px',
                    '.MuiAlert-icon': { marginRight: 8 },
                    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
                    color: defaultDarkTheme.palette.labelPrimary.main,
                    '& p': {
                        fontWeight:
                            defaultThemeConfig.typography['fontWeightMedium'],
                    },
                },
            },
        },

        MuiDrawer: {
            styleOverrides: {
                root: {
                    '> .MuiPaper-root': {
                        backgroundColor:
                            defaultDarkTheme.palette.background.default,
                        borderTopLeftRadius: '24px',
                        borderTopRightRadius: '24px',
                        boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.08)',
                    },
                },
            },
        },
    },
});
