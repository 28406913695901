import { useState, useCallback, useEffect } from 'react';
import useDialog from 'modules/dialog/useDialog';
import { notificationEmailApi } from 'modules/api/client';
import useLoading from 'modules/stores/loading/useLoading';
import IData from 'components/layout/footer/interfaces/IData';
import useNotification from 'modules/notification/useNotification';
import { SuggestionDialogContent } from 'components/layout/footer/components/suggestion/SuggestionDialogContent';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { EUrlHash } from 'components/layout/footer/enums/EUrlHash';

interface IReturn {
    dialogComponent: JSX.Element | null;
    send: () => void;
}

export const reasons: IOption[] = [
    { value: '0', name: 'Zlý inzerát (nevhodný obsah, podvodný inzerát)' },
    { value: '1', name: 'Neaktuálny inzerát' },
    { value: '2', name: 'Nesprávna kategória' },
];

export const useSendSuggestion = (): IReturn => {
    const [isSending, setIsSending] = useState<boolean>(false);
    const { start: startLoading, stop: stopLoading } = useLoading();
    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useNotification();

    const handleOnSubmit = async (values: IData) => {
        setIsSending(true);
        startLoading();

        const selectedReason = reasons[Number(values.reason)];

        try {
            const sendResponse = await notificationEmailApi.notificationControllerSendSuggestion(
                {
                    reason: selectedReason ? selectedReason.name : 'Neznámy dôvod',
                    text: values.text,
                },
                {
                    headers: {
                        'recaptcha-token': values.recaptchaToken,
                        'recaptcha-type': 'tickbox',
                    },
                }
            );

            if (sendResponse.status !== 201) {
                throw `Response status was: ${sendResponse.status}`;
            }

            showSuccessNotification('Pripomienka bola úspešne odoslaná');
        } catch (error) {
            showErrorNotification('Nepodarilo sa odoslať pripomienku');
        } finally {
            setIsSending(false);
            stopLoading();
            hideDialog();
        }
    };

    const { component: dialogComponent, show: showDialog, hide: hideDialog } = useDialog({
        title: 'Pripomienky a nápady',
        content: <SuggestionDialogContent
            isDisabled={isSending}
            onSubmit={handleOnSubmit}
        />
    });

    const send = useCallback(
        () => {
            showDialog();
        },
        [showDialog]
    );

    useEffect(() => {
        if (window.location.hash === EUrlHash.PRIPOMIENKY) {
            showDialog();
            window.location.hash = '';
        }
    }, [showDialog]);

    return {
        dialogComponent,
        send
    };
};
