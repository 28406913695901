import { FC } from 'react';
import { IconButton } from '@mui/material';
import ColorLens from '@mui/icons-material/ColorLens';
import ETheme from 'modules/theme/state/ETheme';
import useTheme from 'modules/theme/state/useTheme';

export const ThemeModeSwitcherBtn: FC = () => {
    const { theme, setTheme } = useTheme();

    return <IconButton
        size='small'
        color='primary'
        aria-label='Theme switcher'
        aria-labelledby='theme-switcher'
        onClick={() => {
            setTheme(theme === ETheme.LIGHT
                ? ETheme.DARK
                : ETheme.LIGHT
            );
        }}
    >
        <ColorLens/>
    </IconButton>;
};
