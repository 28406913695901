import { create } from 'zustand';

export interface IOpenState {
    [key: string]: boolean;
}

type LayoutStore = {
    headerLoaderState: number;
    setHeaderLoaderState: (percentage: number) => void;

    openMenuState: IOpenState;
    setOpenMenuState: (state: IOpenState) => void;
};

export const useLayoutStore = create<LayoutStore>((set) => ({
    headerLoaderState: 0,
    setHeaderLoaderState: (percentage) => set(() => ({
        headerLoaderState: percentage,
    })),

    openMenuState: {},
    setOpenMenuState: (state) => set(() => ({
        openMenuState: state,
    })),
}));