'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import useSessionFetch from 'modules/stores/session/useFetch';
import { Box } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { SnackbarProvider } from 'notistack';
import Header from 'components/layout/header/components/Header';
import Main from 'components/layout/main/Main';
import Footer from 'components/layout/footer/components/Footer';
import isHeaderHidden from 'modules/layout/isHeaderHidden';
import isFooterHidden from 'modules/layout/isFooterHidden';
import { usePathname } from 'next/navigation';
import { usePageLoaderListener } from 'modules/page/app/usePageLoaderListener';
import { useNotificationCenter } from 'components/notificationCenter/hooks/useNotificationCenter';
import { CookieSetter } from 'components/abTesting/CookieSetter';
import { makeStyles } from '@mui/styles';
import { AuthenticateSearchAgent } from 'components/searchAgent/components/authenticateSearchAgent/AuthenticateSearchAgent';

const useStyles = makeStyles({
    snackbarContainerTopCenter: {
        top: 100,
    },
});

const isInitialMargin = (pathname: string): boolean => {
    return pathname.startsWith('/detail') ||
        pathname.startsWith('/caroffice') ||
        pathname === '/';
};

interface IProps {
    children: ReactNode | ReactNode[];
}

export const AppLayout: FC<IProps> = ({ children }) => {
    useSessionFetch();
    usePageLoaderListener();

    const { notification } = useNotificationCenter();

    const pathname = usePathname();
    const [mt, setMt] = useState(isInitialMargin(pathname) ? '64px' : '0px');
    const navHeight = 64;

    const classes = useStyles();

    useEffect(() => {
        const updateMargin = () => {
            const notificationWrapper = document.getElementById('notification-wrapper');
            if (isHeaderHidden(pathname)) {
                setMt('0px');
                return;
            }
            const notificationHeight = notificationWrapper?.offsetHeight || 0;

            setMt(`${notificationHeight + navHeight}px`);
        };

        updateMargin();

        const observer = new MutationObserver(updateMargin);
        const notificationWrapper = document.getElementById('notification-wrapper');
        if (notificationWrapper) {
            observer.observe(notificationWrapper, { childList: true, subtree: true });
        }

        return () => {
            observer.disconnect();
        };
    }, [setMt, pathname, notification]);

    return <>
        <SnackbarProvider
            classes={{
                containerAnchorOriginTopCenter: classes.snackbarContainerTopCenter,
            }}
        >
            <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box display={{ xs: isHeaderHidden(pathname) ? 'none' : 'block', md: 'block' }}>
                    <Header />
                </Box>

                <Box component='main' sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                        mt,
                    },
                    flex: '1 0 auto',
                    display: 'flex',
                    width: '100%',
                })}>
                    <Main>{children}</Main>
                </Box>
                <Box component='footer' sx={{
                    flexShrink: 0,
                    display: isFooterHidden(pathname) ? 'none' : undefined,
                }}>
                    <Footer />
                </Box>
            </Box>
            <CookieSetter/>
            <AuthenticateSearchAgent/>
        </SnackbarProvider>
    </>;
};
