import React, { FC } from 'react';
import { Grid, Box } from '@mui/material';
import { Navigation } from 'components/layout/header/components/navbar/common/Navigation';
import { HeaderWrapper } from 'components/layout/header/components/headerWrapper/HeaderWrapper';
import { Navigation as MobileNavigation } from 'components/layout/header/components/navbar/common/mobile/Navigation';
import { Navigation as DesktopNavigation } from 'components/layout/header/components/navbar/common/desktop/Navigation';

const Header: FC = () => {
    return <HeaderWrapper>
        <Grid container alignItems='center'>
            <Grid item xs={6}>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <MobileNavigation/>
                </Box>

                <Box display={{ xs: 'none', lg: 'block' }}>
                    <DesktopNavigation/>
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Navigation/>
            </Grid>

        </Grid>
    </HeaderWrapper>;
};

export default Header;
