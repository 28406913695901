import { FC } from 'react';
import { TextArea } from 'modules/theme/components/fields/textArea/TextArea';

interface ITextInput {
    value?: string;
    errMsg?: string
    isDisabled?: boolean;
    onChange: (value: string) => void;
}

export const TextInput: FC<ITextInput> = ({ value, isDisabled, errMsg, onChange }) => {
    return <TextArea
        rows={5}
        value={value}
        errMsg={errMsg}
        onChange={onChange}
        disabled={isDisabled}
        placeholder='Ak chcete, aby sme Vám odpovedali, uveďte do textu správy e-mail alebo telefón.'
    />;
};
