import { FC } from 'react';
import MenuItem from 'components/layout/header/components/navbar/common/menu/MenuItem';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

export const AdminLink: FC = () => {
    return <MenuItem
        isStrong
        text='Admin'
        openInNewTab
        href={AppConfigService.getNehnutelnostiAdminUrl()}
    />;
};

